<template>
  <div class="catalog">
    <heads></heads>
    <div class="con_box2">
      <ul class="content">
        <!-- <li>
          <router-link to="AuxiliaryReading2" style="margin-top: 10px"
            >中小学传统文化数字资源研究传播中心配套校园终端服务器</router-link
          >
        </li> -->
        <li>
          <router-link to="AuxiliaryReading1" style="margin-top: 10px"
            >人教版全国中小学传统文化教学一体机</router-link
          >
        </li>

        <li v-for="(item, index) in bookCategorys" :key="index">
          <router-link
            :to="{
              path: pathUrl,
              query: { id: item.id },
            }"
            >{{ item.name }}</router-link
          >
        </li>
        <li>
          <router-link to="ProjectIntroduction" style="margin-top: 10px"
            >课题研究介绍</router-link
          >
        </li>
      </ul>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import "@css/ico.css";
import "@css/foot.css";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";
import { getbookCategorys } from "@api/public";

export default {
  components: {
    heads,
    foot,
  },
  props: {},
  data() {
    return {
      bookCategorys: [],
      backgroud: "",
      pathUrl: "",
    };
  },
  mounted: function () {
    let that = this;
    getbookCategorys().then(function (res) {
      that.$set(that, "bookCategorys", res.data);
    });
  },
  created: function () {
    this.pathUrl = this.$route.meta.path;
    this.backgroud = this.$route.meta.background;
    document.title = this.$route.meta.title;
  },
};
</script>
<style>
.catalog > .con_box2 {
  width: 100%;
  height: 1234px;
  background: url(../../assets/images/catalog.png);
  background-size: cover;
  background-position: center 0;
}
.catalog .content {
  width: 960px;
  height: 720px;
  position: relative;
  top: 235px;
  left: 565px;
  display: flex;
  box-sizing: border-box;
  padding-top: 100px;
  padding-left: 140px;
}
.catalog .content > li {
  font-family: "楷体";
  writing-mode: vertical-lr;
  padding-right: 40px;
  font-size: 20px;
  font-weight: 600;
}
.catalog .content > li > a {
  color: #000;
}
.catalog .content > li > a:hover {
  color: #d8c8a5;
}
</style>